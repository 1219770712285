import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import 'bootstrap'
import $ from "jquery"
import 'slick-carousel'
import AOS from 'aos'
/*
require('bootstrap');
*/

$(document).ready(function(){

  $('#btn_reject').on('click',function(){
    $.ajax({url: "reject_cookies", success: function(result){
      $(".cookieconsent_box").hide();
    }});
  });

  $('#btn_accept').on('click',function(){
    $.ajax({url: "accept_cookies", success: function(result){
      $(".cookieconsent_box").hide();
    }});
  });

    scrollFunction();
    AOS.init();

    $('.btn-newsletter').on('click',function(){
      if ($('.box-newsletter').hasClass("closed")) {
        $('.box-newsletter').removeClass('closed');
      }else{
        $('.box-newsletter').addClass('closed');
      }
    });

    $('.services-slider').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ]
    });

    //newsletter
    var form_newsletter = document.querySelectorAll('.form-newsletter');
    Array.prototype.slice.call(form_newsletter)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    });

    //form home
    var form_home = document.querySelectorAll('.form-home-send');
    Array.prototype.slice.call(form_home)
    .forEach(function (form) {
      form.addEventListener('submit', function (event) {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')
      }, false)
    });

     //form contact
     var form_contact = document.querySelectorAll('.form-contact-main');
     Array.prototype.slice.call(form_contact)
     .forEach(function (form) {
       form.addEventListener('submit', function (event) {
         if (!form.checkValidity()) {
           event.preventDefault()
           event.stopPropagation()
         }
 
         form.classList.add('was-validated')
       }, false)
     });

});

function scrollFunction() {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      $('#navbar').addClass('scrolled');
    } else {
        $('#navbar').removeClass('scrolled');
    }
}
window.onscroll = function() {scrollFunction()};


window.$ = window.jQuery = require('jquery');
window.toastr = require('toastr');

window.handlePhone = (event) => {
  let input = event.target
  input.value = phoneMask(input.value)
}

window.phoneMask = (value) => {
  if (!value) return ""
  value = value.replace(/\D/g,'')
  value = value.replace(/(\d{2})(\d)/,"($1) $2")
  value = value.replace(/(\d)(\d{4})$/,"$1-$2")
  return value
}


window.handleCNPJ = (event) => {
  let input = event.target
  input.value = cnpjMask(input.value)
}

window.cnpjMask = (value) => {
  if (!value) return ""
  value = value.replace(/\D/g,'')
  value = value.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
  return value
}